@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

:root{
  --theme-main-color: #6558F5;
  --theme-secondary-color: #F5BC00;
  --plyr-color-main: #6558F5;
  #tns1 > .tns-item{
    @apply text-base;
  }
  .text-base {
    font-size: 1rem;
  }
}

html, body{
  height: 100%;
}
.h-screen-fix{ height: calc(100vh - 65px) }
.min-h-screen-fix{ min-height: calc(100vh - 65px) }

/* .btn{
  @apply rounded text-white shadow-sm px-6 py-4 font-bold transition duration-150 ease-in-out;
  &--primary{ @apply bg-theme-indigo; &:hover{ background: red } }
  &--yellow{ @apply bg-theme-yellow }
  &--yellow:hover{ @apply bg-theme-yellow opacity-75 }
  &--gray{ @apply bg-gray-200 }
  &--gray:hover{ @apply bg-gray-100 }
} */

.tns-slider {
	transition: all .2s;
}

.cols{
  @apply flex flex-wrap w-full justify-between;
  > * {
    @apply w-full;
  }
}

.form-input, .form-textarea, .form-select{
  @apply appearance-none transition-default delay-75 block w-full rounded px-4 py-3 border-gray-200 placeholder-gray-300;
  color: var(--theme-main-color);
  &:focus{
    border-color: var(--theme-main-color);
    box-shadow: 0 0 0 3px rgba(var(--theme-main-color-rgb), 0.2);
  }
}

.c--form{
  label{ @apply block cursor-pointer }
  input{ @apply text-base transition ease-in duration-100 }
  span{ @apply text-gray-700 }

  .validation-errors li { @apply text-red-600; }
  .hint{ @apply block text-gray-400 }
  .error{ @apply mt-2 block text-red-600 }
  .field{ @apply my-5 }

  .field.select select{ @extend .form-select }
  .field.string input, .field.email input, .field.password input { @extend .form-input }

  .field.field--with-errors{
    input, select{ @apply ring-2 ring-red-500 ring-opacity-50 }
  }
  .field.field--inline{
    .radio label{ @apply inline w-auto }
    .radio input{ @apply inline w-auto }
  }

  input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type=number] {
    @extend .form-input;
    -moz-appearance: textfield;
  }
  .input--pin{
    @extend .form-input;
    @apply px-0 pl-0 pr-0 text-center text-xl;
    &[disabled]{ @apply bg-gray-100 text-gray-700 }
  }
}
.c--survey-steps{

  .carousel {
    &.tns-slider { opacity: 1 }
  }

  .blink {
    animation: blink-animation .15s 3 cubic-bezier(.36,.07,.19,.97) both;
  }
  /* @keyframes blink-animation {
    50%{ opacity: 0.8 }
    100%{ opacity: 1 }
  } */

  @keyframes blink-animation {
    100% { transform: scale(0.96) }
    50%{ transform: scale(1.04) }
    100% { transform: scale(1) }
  }

  /* @keyframes shake-animation {
    10%, 90% { transform: translate3d(-1px, 0, 0) }
    20%, 80% { transform: translate3d(2px, 0, 0) }
    30%, 50%, 70% { transform: translate3d(-4px, 0, 0) }
    50%{ transform: scale(1.2) }
    40%, 60% { transform: translate3d(4px, 0, 0) }
    100% { transform: scale(1) }
  } */

}

.c--form--profiles-assignment{

  input[type="radio"]{
    display: none;
  }
  label{
    @apply transition-default;
    &:hover{
      border-color: var(--theme-secondary-color);
      color: var(--theme-secondary-color);
    }
  }
  input:checked + label{
    @apply rounded text-white border-transparent ring-4 ring-gray-200 ring-opacity-40;
    border-color: var(--theme-secondary-color);
  }
}

.c--tabs--indicator\:indicator-more{
  &:before{
    content: "+ ";
    @apply pr-4;
  }
}
.c--tabs--indicator\:indicator-less{
  &:before{
    content: "- ";
    @apply pr-4;
  }
}
.article{
  h1{ @apply font-style-title text-2xl my-8 }
  p{ @apply my-4 }
}

#unsupported_browser_message {
  background-color: #ffb;
}

.loader {
  position: relative;
  width: 64px;
  height: 64px;
  margin: 12rem auto;
}

.loader div {
  position: absolute;
  border: 4px solid var(--theme-main-color);
  border-radius: 50%;
  animation: loader8435 1s ease-out infinite;
}

.loader div:nth-child(2) {
  animation-delay: -0.5s;
}

[data-controller="shared--video-player--video-player-component"] media-player {
  @apply sm:shadow-md
}

.btn--sm {
  padding: 5px 15px !important;
}

.animate-spin.rounded-full{
  border-color: var(--theme-main-color);
}

.bg--primary{
  background-color: var(--theme-main-color);
}

.text--primary{
  color: var(--theme-main-color);
}

.border-gray-400 {
  border-color: #9ca3af;
}

.h-64 {
  height: 16rem;
}

.mt-52 {
  margin-top: 13rem;
}

.mt-48 {
  margin-top: 12rem;
}

label[class*="bg-gray-400"]:hover {
  background-color: #9ca3af;
  border-color: #9ca3af;
  cursor: not-allowed;
}

dialog.slideover[open] {
  animation: slide-in-from-left 250ms forwards ease;
  @apply flex flex-col items-start;
}

@keyframes slide-in-from-left{
  from {
    transform: translateX(-100%);
  }
}

@keyframes loader8435 {
  0% {
    top: 32px;
    left: 32px;
    width: 0;
    height: 0;
    opacity: 1;
  }

  100% {
    top: 0;
    left: 0;
    width: 64px;
    height: 64px;
    opacity: 0;
  }
}