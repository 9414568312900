@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

.c--survey-question-step{

  input, textarea, button, label{
    @apply transition-default;
  }

  .choices--text, .choices--string{
    input, textarea{ @apply border-2 border-gray-200 py-3 px-5 rounded-sm w-3/4 block mx-auto w-full max-w-3xl transition-default text-theme-indigo text-base }
    input:focus, textarea:focus{ @apply border-theme-indigo }
    textarea[disabled="disabled"]{ @apply bg-gray-50 border-transparent }
  }

  .choices--checkboxes, .choices--radios, .choices--nodes{
    @apply flex flex-wrap items-center justify-center;

    label{}
    label:hover{ @apply bg-theme-yellow-2 border-theme-yellow-2 }


    input{ @apply hidden }
    input:checked + label, label.selected{ @apply bg-transparent rounded-md bg-theme-yellow-2 text-white border-2 border-theme-indigo shadow }

    label.correct, input:checked + label.correct{
      @apply rounded-md bg-green-400 text-white border-2 border-green-400;
      animation: shake-animation 0.8s cubic-bezier(.36,.07,.19,.97) both;
      transform: translate3d(0, 0, 0);
    }
    input:checked + label.correct {
      @apply text-white border-green-500
    }
    label.incorrect, input:checked + label.incorrect{
      @apply rounded-md bg-red-400 text-white border-2 border-red-400;
      animation: shake-animation 0.8s cubic-bezier(.36,.07,.19,.97) both;
      transform: translate3d(0, 0, 0);
    }
    input:checked + label.incorrect {
      @apply text-white border-red-500
    }
  }

  label.disabled{
    @apply bg-gray-100 border-gray-100 text-gray-400;
  }
  label.disabled:hover{
    @apply bg-gray-100 border-gray-100 text-gray-400;
  }


  &[data-disabled="true"]{
    label, input, button{
      filter: grayscale(1);
      opacity: .25
    }
    button{ display: none }
  }

  @keyframes shake-animation {
    10%, 90% { transform: translate3d(-1px, 0, 0) }
    20%, 80% { transform: translate3d(2px, 0, 0) }
    30%, 50%, 70% { transform: translate3d(-4px, 0, 0) }
    50%{ transform: scale(1.2) }
    40%, 60% { transform: translate3d(4px, 0, 0) }
    100% { transform: scale(1) }
  }
}
